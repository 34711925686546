
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import axios, { CancelTokenSource } from 'axios';
import { Participant } from '@/custom/research/models/participant.model';
import { AnonymousParticipantService } from '@/custom/research/services/anonymous-participant.service';
import { INotification } from '@/lib';
import { NavLink, PageLoading, NotificationStack, BaseFooter, BaseIcon } from '@/lib/components';
import { participantGenderOptions } from '@/constants';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    participantId: {
      type: String,
      required: true
    }
  },
  components: { NavLink, BaseIcon, BaseFooter, NotificationStack, PageLoading }
})
export default class ParticipantPage extends Vue {
  participant: Participant | null = null;
  participantId!: string;
  top = 0;
  request: CancelTokenSource | null = null;
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get service(): AnonymousParticipantService {
    return new AnonymousParticipantService(this.organisationId);
  }

  get gender() {
    return (
      this.participant &&
      participantGenderOptions().find((option) => option.value === (this.participant && this.participant.sex))
    );
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get notifications(): Array<INotification> {
    return useNotificationStore().notifications;
  }

  mounted() {
    this.fetchParticipant();
    const nav = this.$refs.nav as HTMLElement;
    if (nav) {
      this.top = nav.clientHeight;
    }
    window.addEventListener('scroll', this.onScroll);
  }

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchParticipant() {
    try {
      this.request = axios.CancelToken.source();
      const participant = await this.service.fetch(this.participantId, {
        cancelToken: this.request.token
      });
      this.request = null;
      this.participant = participant;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.participant.fetch-one-error')
        });
      }
    }
  }

  update(participant: Participant) {
    this.participant = participant;
  }

  onScroll() {
    const nav = this.$refs.nav as HTMLElement;
    if (nav) {
      const rect = nav.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        this.top = rect.height;
      } else {
        this.top = 0;
      }
    }
  }

  onNotificationsUpdate(notifications: INotification[]) {
    this.notificationStore.notifications = notifications;
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  age(dob: string) {
    return dayjs().diff(dayjs(dob), 'year');
  }
}
