import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-between min-h-screen"
}
const _hoisted_2 = {
  ref: "nav",
  class: "bg-white border-b"
}
const _hoisted_3 = { class: "flex justify-between w-full p-5 mx-auto max-w-screen-xl" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "ml-4 text-base font-medium uppercase text-gray-900" }
const _hoisted_6 = { class: "grid gap-x-8 grid-rows-2 grid-flow-col" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_8 = { class: "text-gray-500 mr-3 text-sm font-medium" }
const _hoisted_9 = { class: "text-sm font-medium text-gray-900 text-right" }
const _hoisted_10 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_11 = { class: "text-gray-500 mr-3 text-sm font-medium" }
const _hoisted_12 = { class: "text-sm font-medium text-gray-900 text-right" }
const _hoisted_13 = {
  key: 2,
  class: "flex justify-between"
}
const _hoisted_14 = { class: "text-gray-500 mr-3 text-sm font-medium" }
const _hoisted_15 = { class: "text-sm font-medium text-gray-900 text-right" }
const _hoisted_16 = { class: "flex justify-between" }
const _hoisted_17 = { class: "text-gray-500 mr-3 text-sm font-medium" }
const _hoisted_18 = { class: "text-sm font-medium text-gray-900 text-right" }
const _hoisted_19 = { class: "border-t flex flex-grow w-full h-16 mx-auto max-w-screen-xl" }
const _hoisted_20 = { class: "relative" }
const _hoisted_21 = {
  key: 1,
  class: "flex items-center justify-center w-full min-h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavLink = _resolveComponent("NavLink")!
  const _component_NotificationStack = _resolveComponent("NotificationStack")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_BaseFooter = _resolveComponent("BaseFooter")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_ctx.participant)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_router_link, {
                  to: "/participants",
                  class: "flex items-center justify-center w-10 h-10 shadow rounded bg-primary-500 hover:bg-primary-600 focus:outline-none focus:bg-primary-600"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseIcon, {
                      name: "arrow-left",
                      class: "text-lg text-white stroke-1.5"
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, " ID: " + _toDisplayString(_ctx.participant.custom_identifier), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.participant.date_of_birth)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('platform.patient.dob')), 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.formatDob(_ctx.participant.date_of_birth)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.participant.date_of_birth)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('platform.patient.age')), 1),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.age(_ctx.participant.date_of_birth)) + " years", 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.participant.sex)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('platform.patient.sex')), 1),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.gender && _ctx.gender.label), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('platform.patient.ethnicity')), 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.participant.ethnicity ? _ctx.participant.ethnicity.title : _ctx.$t('platform.patient.not-specified')), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_NavLink, {
                to: '/participants/' + _ctx.$route.params.participantId + '/forms'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.forms')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_NavLink, {
                to: '/participants/' + _ctx.$route.params.participantId + '/details'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.details')), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ], 512),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_NotificationStack, {
              class: "mr-6 mt-6 z-40",
              style: _normalizeStyle('top:' + _ctx.top + 'px;'),
              "display-items": 6,
              "model-value": _ctx.notifications,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onNotificationsUpdate($event)))
            }, null, 8, ["style", "model-value"])
          ]),
          _createVNode(_component_RouterView, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    participant: _ctx.participant,
                    onUpdated: _ctx.update
                  }, null, 8, ["participant", "onUpdated"]))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_BaseFooter)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_21, [
        _createVNode(_component_PageLoading)
      ]))
}